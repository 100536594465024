<template>
  <v-container>
    <v-row>
      <div class="float-center mt-6" v-if="!workInfo.id">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        作品信息正在加载中
      </div>
      <v-col cols="12" v-else-if="workInfo.isauthor || workInfo.publish">
        <v-card class="mx-auto pa-5 my-12 sd">
          <v-row>
            <v-col cols="12">
              <v-alert dense="" outlined="" color="orange" type="info" v-if="!workInfo.publish">
                当前作品还没有发布，仅自己可见，请前往
                <a :href="'#page=workinfo&id=' + workInfo.id">作品信息编辑页</a>
                勾选发布，再点更新，即可发布
              </v-alert>
              <v-alert dense="" outlined="" type="error" v-if="workInfo.ban">
                当前作品已被封禁，请联系
                <a href="#page=studio&amp;id=286">管理员</a> 进行申诉
              </v-alert>
              <span class="text-h5" cols="24">{{ workInfo.name
                }}<span v-if="workInfo.notnew">
                  <v-btn class="ma-2 sd" v-on:click="pub()"> 发布最新版 </v-btn><span
                    style="color: #888; font-size: 0.5em">注：点了这个按钮后，其他人访问你的作品，才会显示现在的最新版本，否则将会显示上一次点这个按钮、或创作页中的发布、或首次发布时的版本</span></span></span>
              <div cols="24">
                <a :href="`#page=user&id=` + workInfo.author">
                  <v-avatar size="40">
                    <v-img cover :src="host.data +
                      '/static/internalapi/asset/' +
                      (workInfo.head ||
                        '6e2b0b1056aaa08419fb69a3d7aa5727.png')
                      " />
                  </v-avatar>
                  <span class="text-subtitle-1" :href="'#page=user&id=' + workInfo">{{ workInfo.nickname }}</span>
                </a>
                <span class="text-subtitle-1 text-right text--disabled mx-2">创建:{{ date(workInfo.time) }}
                </span>
                <span class="text-subtitle-1 text-right text--disabled mx-2">更新:{{ date(workInfo.update_time) }}
                </span>
                <span class="text-subtitle-1 text-right text--disabled mx-2">发布:{{ date(workInfo.publish_time) }}</span>
              </div>
            </v-col>
            <v-col cols="12" md="7">
              <v-responsive :aspect-ratio="51 / 44" id="work">
                <iframe v-if="workInfo.id && (workInfo.isauthor || workInfo.publish) && myInfo" class="rounded-lg"
                  :src="'/embed.html?v=15#id=' + workInfo.id" scrolling="no" frameborder="0"
                  style="width: 100%; height: 100%"></iframe>
                <center v-else>
                  <span class="mt-5" v-if="myInfo">无权限访问此作品</span>
                  <span class="mt-5">请先<a :href="loginHash">登录</a></span>
                </center>
              </v-responsive>
              <v-btn variant="plain" text="">
                <v-icon> mdi-eye </v-icon>{{ workInfo.look }}
              </v-btn>
              <v-btn v-on:click="like()" :color="workInfo.islike ? 'red' : ''" variant="plain" text="">
                <v-icon> mdi-heart </v-icon>{{ workInfo.like }}
              </v-btn>
              <v-btn variant="plain" text="" v-on:click="
                workInfo.is_collection ? removeCollection() : collection()
                " :color="workInfo.is_collection ? 'yellow' : ''">
                <v-icon> mdi-star </v-icon>{{ workInfo.num_collections }}
              </v-btn>
              <v-btn variant="plain" v-on:click="analysis()" color="#666">
                <v-icon>
                  mdi-search
                </v-icon>代码分析
              </v-btn>
              <v-btn variant="plain" v-on:click="reporter()" color="red">
                <v-icon>
                  mdi-alert-octagon
                </v-icon>举报
              </v-btn>
              <br />
              <br />
              <v-row>
                <Item :host="host" :myInfo="myInfo" :page="1" :id="id" class="ml-4 mt-n4 mb-3" :power="workInfo.isauthor"></Item>
              </v-row>
            </v-col>
            <v-col>
              <v-row :style="'height:' + iheight">
                <v-col cols="12" :style="'height:' + iheight" class="overflow-auto">
                  <span class="text-h5 text--primary" cols="24">作品介绍</span>
                  <br />
                  <br />
                  <MarkdownContext :text="workInfo.introduce" />
                  <br />
                  <br />
                </v-col>
                <v-col>
                  <v-btn class="ma-2 sd" :href="'/editor.html#id=' + workInfo.id" target="_blank" v-bind:disabled="!(workInfo.isauthor || workInfo.opensource)
                    ">
                    转到创作页
                  </v-btn>
                  <v-btn class="ma-2 sd" :href="'#page=workinfo&id=' + workInfo.id" v-if="workInfo.isauthor">
                    编辑作品信息
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <div class="float-center" v-else>无权限查看当前作品</div>
      <v-col cols="12" v-if="workInfo && (workInfo.isauthor || workInfo.publish)">
        <CommentArea :id="id" :host="host" :type="1" :myInfo="myInfo"></CommentArea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { defineProps, ref, defineEmits, onUnmounted } from "vue";
import CommentArea from "./CommentArea.vue";
import MarkdownContext from "./MarkdownContext.vue";
import Item from "./SItem.vue";
var workInfo = ref({}),
  date = window.convertTimestamp,
  id = ref(window.getQueryString("id"));
var iheight = ref(0);
let loginHash='#page=sign&url='+btoa(location.hash)
defineProps(["host", "myInfo"]);
defineEmits(["updateValue"]);
window
  .get("work/info", {
    id: id.value,
    sha: window.getQueryString("sha"),
    etime: window.getQueryString("etime"),
  })
  .then((d) => {
    (workInfo.value = d.data)
    window.setTitle(workInfo.value.name)
  });
let intervalId = setInterval(() => {
  if (window.innerWidth >= 960) iheight.value = window.$("#work").height() + "px";
  else iheight.value = "auto";
}, 100);
onUnmounted(() => clearInterval(intervalId));
var like = () => {
  window
    .post("work/like" + (workInfo.value.islike ? "/cancel" : ""), {
      id: id.value,
    })
    .then(() => {
      if (workInfo.value.islike) workInfo.value.like--;
      else workInfo.value.like++;
      workInfo.value.islike = !workInfo.value.islike;
    });
};
var collection = async () => {
  if (await window.dialog(null, { folder: id.value }, 1)) {
    workInfo.value.num_collections++;
    workInfo.value.is_collection = true;
  }
};
var removeCollection = () => {
  window
    .post("/collections/cancel", {
      work_id: id.value,
    })
    .then(() => {
      workInfo.value.num_collections--;
      workInfo.value.is_collection = false;
    });
};
var pub = () => {
  window.post("work/pub", { id: id.value });
};
window.setTitle('作品')
var analysis = () => {
  window.dialog(null, 'analysis')
}
var reporter=()=>{
  window.dialog(null,{reporter:{href:location.hash,name:workInfo.value.name}})
}
</script>