<template>
    <div class="mb-3">
        <v-file-input accept="image/png, image/jpeg, image/bmp, image/gif" :label="`${name || '作品封面图'}(建议尺寸${bl||'1:1'})`"
            show-size truncate-length="10" @change="upload()" v-model="files"></v-file-input>
        <v-img :src="host.data + '/static/internalapi/asset/' + image" :aspect-ratio="4 / 3" class="ma-5 pb-2"></v-img>
        <center v-if="cover == 1"><v-progress-circular color="primary" indeterminate></v-progress-circular>正在上传中</center>
        <center v-if="cover == -1">上传成功</center>
    </div>
</template>
<script setup>
import { defineProps, defineModel,ref } from 'vue';
defineProps(['host', 'name','bl'])
const image = defineModel({ default: '' })
const files=ref([]),cover=ref(0)
var upload = () => {
    if (!files.value[0]) {
        return;
    }
    let data = window.uploadImage(files.value[0]);
    if (data.toString() == "[object Promise]") {
        cover.value = 1;
        data.then((d) => {
            console.log(d)
            cover.value = -1;
            image.value = d;
        });
        return null;
    }
    if (typeof data === "string") {
        return data;
    }
}

</script>