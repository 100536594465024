<template>
    <div style="position: relative; display: inline-block; overflow: visible;">
      <v-avatar :size="size || 120" ref="avatar" style="position: relative; z-index: 1;">
        <v-img
          cover
          :src="host.data +
          '/static/internalapi/asset/' +
          (head || user?.head || '6e2b0b1056aaa08419fb69a3d7aa5727.png')
          "
        />
      </v-avatar>
      <div v-for="(headwear, index) in headwears" :key="index"
           :style="{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: (avatarSize * 3) + 'px',
             height: (avatarSize * 3) + 'px',
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
             overflow: 'visible',
             zIndex: 2 // Ensure headwear is above the avatar
           }">
        <v-img
          :src="host.data + '/static/internalapi/asset/' + headwear"
          :style="{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }"
        />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import { defineProps } from 'vue';
  
  const props = defineProps(['user','head','host', 'size', 'headwears']);
  const avatarSize = ref(props.size || 120);
  const avatar = ref(null);
  
  onMounted(() => {
    if (avatar.value) {
      avatarSize.value = avatar.value.$el.clientWidth;
    }
  });
  
  watch(() => props.size, (newSize) => {
    if (avatar.value) {
      avatarSize.value = newSize || 120;
    }
  });
  </script>
  
  <style scoped>
  /* Add any additional styling you need here */
  </style>