<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="[
          { title: studio.name, href: '#page=studio&id=' + studio.id },
          { title: '论坛', href: '#page=forum&sid=' + studio.id },
        ]" divider=">"></v-breadcrumbs>
        <!-- 显示备注 -->
        <v-alert v-if="studio.notes" type="info" class="mb-4">
          {{ studio.notes }}
        </v-alert>
        <v-alert type="info" v-if="studio.postPermission && !userIsMember" class="mb-4">
          当前工作室设置禁止非成员发帖
        </v-alert>
        <v-row>
          <v-col cols="4">
            <v-btn color="red lighten-2" dark @click="checkPermissionsAndOpenDialog"
              v-if="!studio.postPermission || userIsMember">
              发布帖子
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-text-field label="" onkeypress="13==event.keyCode&&$('#search').click()" class="ml-4"
              v-model="s"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn v-on:click="search" class="float-left ml-4" elevation="0" id="search" large>
              <v-icon>mdi-magnify</v-icon>搜索
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="500">
          <v-card class="px-3">
            <v-card-title class="text-h5 grey lighten-2">帖子发出</v-card-title>
            <!-- 显示备注 -->
            <v-card-subtitle v-if="studio.notes" class="mt-2">
              {{ studio.notes }}
            </v-card-subtitle>
            <v-text-field label="标题" v-model="ftitle"></v-text-field>
            <MarkdownInput label="帖子内容" v-model="fcontext" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-checkbox label="公开于总论坛" :input-value="studio.id == 1" :disabled="studio.id == 1"
                v-model="fpublic"></v-checkbox>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="send" :disabled="sending">发布</v-btn>
              <v-btn color="primary" text @click="dialog = false">取消</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="8"></v-col>
      <v-col cols="12">
        <v-card class="pa-6 sd">
          <div v-if="studio.viewPermission && !userIsMember">
            <v-alert type="info">此工作室设置仅允许成员查看</v-alert>
          </div>
          <div v-else-if="list">
            <div v-for="i in list" :key="i">
              <div class="my-7 d-flex">
                <a :href="'#page=user&id=' + i.author">
                  <v-img cover width="50" height="50" class="rounded-circle pt-4" style="flex: 0 40px;"
                    :src="host.data + '/static/internalapi/asset/' + (user[i.author][0].head || '6e2b0b1056aaa08419fb69a3d7aa5727.png')" />
                </a>
                <span style="flex: 0 10px;"> </span>
                <span>
                  <v-chip color="green" variant="flat" class="mr-1" v-if="i.topic && studio.id==i.topic_sid">置顶</v-chip>
                  <span v-if="i.tags"><v-chip :color="j.color" variant="flat" class="mr-1" v-for="j in (i.tags && JSON.parse(i.tags))" :key="j">{{j.text}}</v-chip></span>
                  <a :href="'#page=post&id=' + i.id" class="text-h6 text-truncate" v-text="i.title"></a>
                  <a :href="'#page=studio&id=' + i.sid" v-if="i.sid && i.sid !== 1" >
                    <v-btn style="text-transform: none !important" :color="i.color || 'green'" class="sd tg" small>
                      <span style="color: #fff">{{ i.name }}</span>
                    </v-btn>
                  </a>
                  <span style="color: #aaa" class="pl-4">#{{ i.id }}</span>
                  <br />
                  <a :href="'#page=user&id=' + i.author" class="mt-1">
                    {{ user[i.author][0].nickname }}
                    <span style="color: #aaa" class="pl-4">{{ date(i.new_time) }}</span>
                  </a>
                </span>

              </div>
            </div>
          </div>
          <div v-else>
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
            正在载入数据……
          </div>
          <v-pagination v-model="currentPage" :length="totalPages" @input="fetchPage" class="mt-4"></v-pagination>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, watch, computed } from "vue";
import MarkdownInput from './MarkdownInput.vue';

defineProps(["host"]);

var list = ref([]),
  studio = ref({}),
  user = ref({}),
  dialog = ref(false),
  sending = ref(0),
  s = ref(getQueryString("name")),
  p = ref(getQueryString("p")) || 1;// 默认页数为1

var date = window.convertTimestamp;
var ftitle = ref(""),
  fcontext = ref(""),
  fpublic = ref(false),
  currentPage = ref(1),
  totalPages = ref(1),
  pageSize = 10; // 每页的数据条数
var userIsMember = ref();

var fetchPage = () => {
  list.value = null;
  get(currentPage.value);
};

var search = () => {
  currentPage.value = 1;
  fetchPage();
};

var send = () => {
  if (ftitle.value.length < 3 || fcontext.value.length < 3) {
    alert("标题和正文必须大于2个字");
    return;
  }

  if (studio.value.postPermission && !userIsMember.value) {
    alert("仅允许成员发帖");
    return;
  }

  // 不允许将帖子公开到总论坛
  if (studio.value.postPermission && fpublic.value && studio.value.id == 1) {
    alert("仅允许成员发帖，不能公开到总论坛");
    return;
  }

  sending.value = 1;
  post("forum/new", {
    title: ftitle.value,
    context: fcontext.value,
    sid: getQueryString("sid"),
    public: fpublic.value,
  }).then(() => {
    sending.value = 0;
    dialog.value = false;
    fetchPage(); // 发送帖子后刷新数据
  });
};

var get = (page) => {
  window
    .get("forum/list", {
      sid: getQueryString("sid"),
      page: page,
      name: s.value,
      pageSize: pageSize
    })
    .then((data) => {
      data = data.data;
      list.value = data.list;
      studio.value = data.studio;
      user.value = data.user;
      totalPages.value = Math.ceil(data.total / pageSize); // 计算总页数
      userIsMember.value = data.userIsMember
      window.setTitle(studio.value.name + ' 论坛');
    });
};

var checkPermissionsAndOpenDialog = () => {
  if (studio.value.postPermission && !userIsMember.value) {
    alert("仅允许成员发帖");
  } else {
    dialog.value = true;
  }
};

watch(currentPage, (newPage) => {
  fetchPage();
});

fetchPage(); // 初始加载
window.setTitle('论坛');
</script>
