<template>
  <v-dialog v-model="dialog2" max-width="600">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close1')">
          <v-icon>mdi-close</v-icon></v-btn
        >
        <v-toolbar-title>物品信息</v-toolbar-title></v-toolbar
      >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">{{
            dialog && dialog.item.name
          }}</v-list-item-title>
          <div
            class="ctext--text"
            v-html="
              markdownToHtml(
                dialog &&
                  ((dialog.options && JSON.parse(dialog.options).descp) ||
                    (dialog.item && dialog.item.descp))
              )
            "
          ></div>
          <br />
          <br />
          <v-list-item-subtitle v-if="dialog.expirationTime"
            >还有
            {{
              ((dialog.expirationTime - new Date() / 1000) / 3600).toFixed(1)
            }}
            小时失效
            <br />
            <br
          /></v-list-item-subtitle>
        </v-list-item-content>
        <v-img
          height="100"
          width="100"
          :src="
            host.data +
            '/static/internalapi/asset/' +
            (dialog.item.thumbId || '6e2b0b1056aaa08419fb69a3d7aa5727.png')
          "
        ></v-img>
      </v-list-item>
      <v-list-item class="ctext--text" v-if="page == 'myitem' && !dialog.buy">
        <v-list-item-text
          >类别: {{ dialog.item.category }} <br />使用位置:
          {{ dialog.item.canUseInBag ? "背包" : "" }}
          {{ dialog.item.canUseToFourmPost ? "帖子" : "" }}
          {{ dialog.item.canUseToProject ? "作品" : "" }}
          {{ dialog.item.canUseToUser ? "个人中心" : "" }}
          {{ dialog.item.canUseToStudio ? "工作室" : "" }} <br />价格：{{
            dialog.item.price
          }}
          金币
          <br />
          <v-list-item-text v-if="dialog.item.canUseToSelfOnly"
            >仅可对自己使用</v-list-item-text
          ></v-list-item-text
        >
      </v-list-item>
      <v-card-actions
        v-if="page == 'myitem' && dialog.item && dialog.item.canUseInBag"
      >
        <v-btn class="my-2 sd" @click="tuse(dialog.item.id)"
          >使用</v-btn
        ></v-card-actions
      >
      <v-card-actions v-if="page == 'myitem' && dialog.buy">
        <v-number-input
          label="数量"
          outlined
          v-model="buynum"
          :min="1"
        ></v-number-input>
        <v-spacer></v-spacer>
        <v-btn class="my-2 sd" @click="buy(dialog.item.id)"
          >购买</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script setup>
/* eslint-disable */
import { defineProps, ref, defineEmits, watch } from "vue";
import { VNumberInput } from "vuetify/labs/VNumberInput";
const props = defineProps(["host", "dialog", "page", "myInfo"]);
var dialog2 = ref(true),
  buynum = ref(1);
const buy = (id) => {
  post("item/buy", {
    itemId: id,
    count: buynum.value,
  }).then(() => emit("close1", "r"));
};
watch(dialog2, (value) => {
  if (!value) emit("close1");
});
const tuse = (id) => {
  let t = window.t
  var tl = window.tl;
  window
    .post("item/use", {
      itemId: id,
      useid: props.myInfo?.id,
      useto: 10,
      options:
        tl[props.id] && tl[props.id].f && eval("(" + tl[props.id].f + ")"),
    })
    .then(() => window.reload2());
};
const emit = defineEmits(["updateValue"]);
const markdownToHtml = window.markdownToHtml;
</script>