<template>
  <span v-bind="$attrs" v-html="r_text" :id="'a' + random_id"> </span>
  <span :id="random_id" style="display: none"></span>
</template>
<script setup>
import { defineProps, ref, watch, onMounted } from "vue";
const random_id = "a" + Math.round(Math.random() * 1e12);
const props = defineProps(["text"]);
const r_text = ref(window.markdownToHtml(props.text || ""));
var viewer;
var render = () => {
  // console.log(props.text);
  window.$("#" + random_id).html(window.markdownToHtml(props.text || ""));
  if (!props.text) return;
  window.scratchblocks.renderMatching(
    `#${random_id} code.language-scratch-blocks,#${random_id} code.language-scratch3`,
    {
      style: "scratch3",
      scale: 1,
    }
  );
  window.scratchblocks.renderMatching(`#${random_id} code.language-scratch2`, {
    style: "scratch2",
    scale: 1,
  });
  r_text.value = window.$("#" + random_id).html();
  setTimeout(() => {
    if (viewer) viewer.update();
    else
      viewer = new window.Viewer(window.$("#a" + random_id)[0], {
        zIndex: 9999,
      });
  }, 50);
};
watch(props, render);
onMounted(render);
</script>