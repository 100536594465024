<template>
    <span>
        <markdown-input clearable  clear-icon="mdi-close-circle" filled v-model="text" label="评论" maxlength="500"
            counter>
        </markdown-input>
        <v-btn class="pa-2 mx-auto sd" v-on:click="send(reply)" block>发送</v-btn>
    </span>
</template>
<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import MarkdownInput from './MarkdownInput.vue';
var props = defineProps(['id', 'type', 'reply','touid','toname']),
    text = ref('');
const emit = defineEmits(['updateValue'])
var send = () => {
    if(props.reply)
    window.post("comment/reply", {
        comment: text.value,
        toid: props.reply,
        torid:props.touid,
        type: props.type
    }).then(()=>{
        emit('reget')
    })
    else
    window.post("comment/", {
        comment: text.value,
        touser: props.id,
        type: props.type
    }).then(()=>{
        emit('reget')
    })
}
</script>