<template>
  <v-container>
    <v-row v-if="works || studio || studio">
      <v-col cols="12" v-for="(n, i) in (works || [])" :key="n">
        <v-row>
          <v-col class="mt-2" cols="12">
            <v-icon>{{ n.icon }}</v-icon>
            <strong>{{ n.title }}</strong>
            <a class="float-right" :href="n.link">
              <v-btn color="primary" class="sd">
                <v-icon>mdi-arrow-right</v-icon>更多
              </v-btn>
            </a>
          </v-col>
          <v-col v-for="j in n.worklist" :key="j" cols="6" sm="4" md="3" lg="2">
            <s-workcard
              :work="j"
              :user="n.userlist[j.author.toString()][0]"
              :host="host"
            >
            </s-workcard>
          </v-col>
        </v-row>
        <v-row v-if="i == 1 && user && user.length">
          <v-col class="mt-2" cols="12">
            <v-icon>mdi-medal</v-icon>
            <strong>用户榜</strong>
            <a
              class="float-right"
              href="#page=search&name=&author=&type=1&p=1&s=1"
            >
              <v-btn color="primary" class="sd">
                <v-icon>mdi-arrow-right</v-icon>更多
              </v-btn>
            </a>
          </v-col>
          <v-col v-for="j in user" cols="6" sm="4" md="3" lg="2" :key="j">
            <s-usercard :user="j" :host="host"></s-usercard>
          </v-col>
        </v-row>
        <v-row v-if="i == 1 && studio && studio.length">
          <v-col class="mt-2" cols="12">
            <v-icon>mdi-format-list-numbered</v-icon>
            <strong>工作室榜</strong>
            <a
              class="float-right"
              href="#page=search&name=&author=&type=2&p=1&s=2"
            >
              <v-btn color="primary" class="sd">
                <v-icon>mdi-arrow-right</v-icon>更多</v-btn
              ></a
            >
          </v-col>
          <v-col v-for="j in studio" cols="6" sm="4" md="3" lg="2" :key="j">
            <s-studiocard :studio="j" :host="host"></s-studiocard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <span v-else>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      正在载入数据……
    </span>
  </v-container>
</template>

<script setup>
import SWorkcard from "./WorkCard.vue";
import SUsercard from "./UserCard.vue";
import SStudiocard from "./StudioCard.vue";
import { ref } from "vue";
import { defineProps } from "vue";
defineProps(["host"]);
var user = ref();
var studio = ref();
var works = ref();
window.get("work/index").then((data) => (works.value = data.data));
window.get("user/clist").then((data) => (user.value = data.data));
window.get("studio/index").then((data) => (studio.value = data.data));
window.setTitle('首页')
</script>