<template>
    <div style="min-height:400px;" class="pa-4">
        <div id="chart"></div>
        <span>总{{chose?'':'有效'}}积木数：{{ totalBlock }}</span>
        <span class="pl-4">总{{chose?'':'有效'}}片段数：{{ chose?c_data.part:c_data.validPart }}</span>
        <span class="pl-4">{{chose?'':'有效'}}声音数：{{ chose?c_data.soundsSum:c_data.soundsSumVaild }}</span>
        <v-radio-group inline v-model="chose">
            <v-radio label="全部" :value="true"></v-radio>
            <v-radio label="仅有效" :value="false"></v-radio>
        </v-radio-group>
        <v-divider></v-divider><br>
        <span class="pl-4">角色数：{{ c_data.jueseSum }}</span>
        <span class="pl-7">造型数：{{ c_data.costumesSum }}</span>
    </div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from 'vue'
const props = defineProps(['title'])
const c_data = ref({}), totalBlock = ref(0), chose = ref(false)
onMounted(() => {
    let option = {
        title: {
            text: props.title || '作品数据统计--40code'
        },
        series: [
            {
                type: 'pie',
                data: [
                ]
            }
        ],
    }
    let t={
        'pen':'画笔',
        'motion':'动作',
        'procedures':'自定义',
        'event':'事件',
        'sensing':'侦测',
        'looks':'外观',
        'data':'数据',
        'operator':'运算',
        'control':'控制'
    }
    var myChart = window.echarts.init(document.getElementById('chart'), null, {
        width: 500,
        height: 400
    });
    myChart.setOption(option);
    window.get('work/work', { id: window.getQueryString('id'), analysis: 1 })
        .then((data) => {
            c_data.value = data
            const { prefixCountAll, prefixCountAllValid } = data
            const update = () => {
                let pc = chose.value ? prefixCountAll : prefixCountAllValid
                console.log(pc)
                let l = [], s = 0;
                for (let key in pc) {
                    let p = pc[key]
                    s += p
                }
                totalBlock.value = s
                for (let key in pc) {
                    let p = pc[key]
                    l.push({ name: `${t[key] || key}(${p})`, value: p })
                }
                option.series[0].data = l
                console.log(l, option)
                myChart.setOption(option);
            }
            update()
            watch(chose,()=>update())
        })
});

</script>