// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      'dark': {
      },
      'light': {
        background: '#eee',
      }
    }
  },
  defaults: {
    VBtn: {
      color: 'primary',
      // variant: 'text',
    },
    VToolbar:{
      VBtn: {
        color: 'white',
      },
    },
    VBtnSecondary: {
      color: 'secondary',
      // variant: 'flat',
    },
  }
})
