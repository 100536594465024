<template>
    <v-row style="padding-top:15px">
        <span style="flex:0 0 50px;max-width:50px" class="ma-2" v-if="myInfo">
            <v-img @click="getItem(1)"
                src="https://abc.520gxx.com/static/internalapi/asset/d0b383f2c6c38eb356e8d60fc7197acf.svg"></v-img>
        </span>
        <span style="flex:0 0 50px;max-width:50px" class="ma-2" v-for="i in items2" :key="i">
            <span v-if="!i.expirationTime || i.expirationTime > new Date / 1000">
                <v-card class="pa-3"
                    style="background-color:rgba(128, 128, 128, 0.05);box-shadow:inset 0 2px 2px 2px #00000006!important"
                    @click="getItem(i)">
                    <v-img :src="'https://abc.520gxx.com/static/internalapi/asset/' + i.item.thumbId"></v-img>
                </v-card>
            </span>
        </span>
        <item-dialog :dialog="selection" v-if="selection" @close1="selection = null" :host="host"
            :page="page"></item-dialog>
        <item-use v-if="showuse" @close1="showuse = false" :host="host" :items="bagItems" :page="page" :myInfo="myInfo"
            :id="showuse" :toid="id"></item-use>
        <item-bag v-if="showbag" @close1="close1" :host="host" :items="bagItems" :page="page" :power="power"
            :myInfo="myInfo"></item-bag>
    </v-row>
</template>
<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import ItemDialog from './ItemDialog.vue'
import ItemUse from './ItemUse.vue'
import ItemBag from './ItemBag.vue'
var props = defineProps(['myInfo', 'host', 'page', 'id', 'data','power'])
defineEmits(['updateValue'])
var showbag = ref(0), showuse = ref(0), bagItems = ref([]), selection = ref(null), items = ref(null), Pendant = ref(null), items2 = ref(null)
const pre = data => {
    data = data.filter(d => (!d.expirationTime || d.expirationTime < new Date / 1000))
    items.value = data.filter(e => e.item.category !== 'Pendant')
    items2.value = items.value.filter(e => (!e.expirationTime || e.expirationTime > new Date / 1000))
    Pendant.value = data.filter(e => e.item.category == 'Pendant')
}
if (props.data)
    pre(props.data)
else
    window.get("item/work", { id: props.id, useto: props.page })
        .then(data => {
            pre(data.data)
        })

/* const get = () => {
    window.get("item/store")
        .then(data => storeItems.value = data.data)
    window.get("item/bag")
        .then(data => bagItems.value = data.data)
}*/
const getItem = (i) => {
    if (i == 1) {
        window.get("item/bag", { p: 'getbag' })
            .then(data => {
                //console.log('1145',data.data)
                bagItems.value = data.data
                showbag.value = true
            })
    } else {
        selection.value = i
    }
    // console.log(selection)
}
const close1 = (i) => {
    showbag.value = false
    console.log(i)
    if(i===0)i='0'
    if (i) {
        showuse.value = i
        //console.log(showuse.value)
    }
}
</script>