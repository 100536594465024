<template>
    <v-container>
        <div id="c2"></div>
        <v-row>
            <v-col class="mx-auto" cols="12" md="6">
                <v-card class="mx-auto pa-8 my-12 sd" width="400">
                    <span>{{ state ? (state == 1 ? '注册' : '找回密码') : '登录' }}</span>
                    <v-text-field label="邮箱或手机号" :rules="rules.email" hide-details="auto" class="my-2"
                        v-model="email"></v-text-field>
                    <v-row v-if="state">
                        <v-col cols="6">
                            <v-text-field v-model="code" label="邮件/短信验证码(6位)" :rules="rules.code" hide-details="auto"
                                class="my-2"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="primary" class="pa-2 sd" v-on:click="sendcode(state)"
                                block>发送验证码</v-btn></v-col>
                    </v-row>
                    <v-text-field v-model="nickname" label="昵称" hide-details="auto" class="my-2"
                        v-if="state == 1"></v-text-field>
                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="rules.password" :type="show1 ? 'text' : 'password'" name="input-1"
                        :label="(state == 2 ? '新密码(你要改成的密码)' : '密码')" hide-details="auto" counter
                        @click:append="show1 = !show1" onkeypress="13==event.keyCode&&window.$('#login').click()"></v-text-field>
                    <div cols="12" class="mx-auto mt-2">
                        <v-row>
                            <v-col cols="12">
                                <v-btn color="primary" id="login" class="pa-2 sd" @click="submit(state)" block>
                                    {{ state ? (state == 1 ? '注册' : '找回密码') : '登录' }}
                                </v-btn>
                            </v-col>
                            <v-col class="mx-auto">
                                <a @click="state = 0" v-if="state == 1" class="mx-2">已有账号？</a>
                                <a @click="state = 0" v-if="state == 2" class="mx-2">登录</a>
                                <a @click="state = 1" v-if="state == 0" class="mx-2">没有账号？</a>
                                <a @click="state = 2" v-if="state == 0" class="mx-2">找回密码</a>
                            </v-col>
                        </v-row>
                    </div>登录或注册即代表同意
                    <a target="_blank" href="/yhxy.html">《用户协议》</a>
                    <!-- <v-divider class="my-4"></v-divider>第三方登录：
                    <a href="https://service-dq726wx5-1302921490.sh.apigw.tencentcs.com/user/3p?type=qq">
                        <v-icon color="primary">mdi-qqchat</v-icon>
                    </a> -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import {  ref } from 'vue'
var state = ref(0), show1 = ref(false), email = ref(''), password = ref(''), nickname = ref(''), code = ref('');
// var dialog = inject('dialog')
const rules = {
    'email': [(email) => /^1[3456789]\d{9}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || "请输入正确的邮箱或手机号"],
    'code': [e => /^\d{6}$/.test(e) || "请输入6位数字验证码"],
    'password': [e => !/^[0-9]*$/.test(e) || e.length > 9 ? e.length < 6 ? "密码必须大于6位" : void 0 : "纯数字密码必须大于9位"]
}
var captchaId = "c0723d25371bf71359761aa06f6075ed"
function initGt(name, callback) {
    window.initGeetest4({
        captchaId: captchaId,
        product: 'bind',
    }, function (gt) {
        gt.appendTo("#c2")
        var $ = window.$
        $('#c2 > #btn').click(function () {
            gt.showBox();
        })
        $('#c2 > #reset_btn').click(function () {
            gt.reset();
        })
        gt.onSuccess(callback)
        window[name] = gt;
    })
}
initGt('gt2', () => {
    var result = window.gt2.getValidate();
    // console.log(email.value)
    window.post("user/sendcode", { email: email.value, t2: result })
        .then(data => (data))
})
initGt('gt3', () => {
    var result = window.gt3.getValidate();
    window.post("user/" + ["login", "signup2", "signup2"][state.value],
        {
            email: email.value,
            t2: result,
            pw: password.value,
            nickname: nickname.value,
            code: code.value,
            type: state.value == 2 ? 1 : 0
        }
    ).then(data => {
        if (data.code == 1) {
            window.setCookie('token', data.token, 3)
            setTimeout(() => {
                location.href = window.getQueryString("url") ? atob(window.getQueryString("url")) : "#"
            }, 100);
        }
    })
})

function sendcode() {
    let r=rules.email[0](email.value)
    if(r && r!=true) return window.alert(r)
    window.gt2.showCaptcha()
}
function submit() {
    let r=rules.email[0](email.value)
    if(r && r!=true) return window.alert(r)
    r=rules.password[0](password.value)
    if(r && r!=true) return window.alert(r)
    window.gt3.showCaptcha()
}
window.setTitle('登录')
</script>