<template>
  <v-container>
    <v-row>
      <v-col class="mx-auto pa-5 my-12" v-if="!user.id">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
        用户信息正在加载中
      </v-col>
      <div class="mx-auto" v-else>
        <v-col cols="12">
          <v-card class="pa-5 sd">
            <v-alert dense outlined type="error" v-if="user.ban">当前用户已被封禁，请联系
              <a href="#page=studio&id=286">管理员</a>进行申诉</v-alert>
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <v-row>
                  <span :class="'avatar id' + user.id"></span>
                  <span style="flex: 0 0 140px">
                    <s-avatar :host="host" :user="user" size="120" :headwears="headwears"></s-avatar>
                  </span>
                  <span style="flex: 0 0 calc(100% - 150px);z-index:6" >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span class="text-h5" v-if="!myInfo || user.id != myInfo.id || edit != 1">{{ user.nickname }}
                          <span style="color: #888; font-size: 13px">#{{ user.id }}</span></span>
                        <v-text-field label="昵称" v-model="nickname" id="nnedit" v-else></v-text-field>
                        <v-btn :prepend-icon="'mdi-square-' +
                          (edit != 1 ? 'edit' : 'off') +
                          '-outline'
                          " class="sd ml-2" v-on:click="edit == 1 ? (edit = 0) : (edit = 1)"
                          v-if="myInfo && user.id == myInfo.id" size="small" :style="myInfo && myInfo.id == user.id && edit == 1
                            ? 'margin-top: -25px;'
                            : ''
                            ">
                          {{ edit == 1 ? "取消" : "" }} 编辑</v-btn>
                        <v-btn v-on:click="edits(1)" v-if="myInfo && user.id == myInfo.id && edit == 1" class="sd ml-2"
                          style="margin-top: -25px" size="small">保存</v-btn>
                        <a :href="`#page=studio&id=${user.studio.id}`" v-if="user.studio">
                          <v-btn style="text-transform: none !important" :color="user.studio.color || 'green'"
                            class="sd tg ml-2" size="small">
                            <span style="color: #fff">{{
                              user.studio.name
                            }}</span></v-btn>
                        </a>
                      </v-col>
                      <v-col cols="12" style="color: #888; font-size: 13px">{{ date(user.signtime) }} 加入</v-col>
                      <v-col cols="12" style="color: #888; font-size: 13px">{{ date(user.last_active) }} 最后活跃</v-col>
                      <v-col cols="12" class="mt-2">
                        <v-btn class="sd" v-on:click="signin()" v-if="myInfo && user.id == myInfo.id">签到</v-btn>
                        <v-btn class="sd" v-on:click="follow()" v-else>{{ user.followu ? "取消" : "" }}关注</v-btn></v-col>
                    </v-row>
                  </span>
                </v-row>
              </v-col>
              <v-col>
                <a>
                  <v-btn class="text-subtitle-1 text--secondary sd tg" size="small" :href="`#page=search&name=&author=&type=1&p=1&s=0&sid=&fl=&fan=` +
                    user.id +
                    '&title=' +
                    user.nickname +
                    '的粉丝'
                    ">{{ user.fan }} 粉丝</v-btn></a>
                <a class="ml-2" :href="`#page=search&name=&author=&type=1&p=1&s=0&sid=&fl=&follow=` +
                  user.id +
                  '&title=' +
                  user.nickname +
                  '的关注'
                  ">
                  <v-btn size="small" class="text-subtitle-1 text--secondary sd tg">{{ user.follow }} 关注</v-btn></a>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="text-subtitle-1 ml-2" v-bind="attrs" v-on="on">
                      <span class="text--disabled">金币：</span>
                      <span style="color: #ff9800">{{ user.coins }}</span></span>
                  </template>
                  <span>获取方式：
                    <br />1.签到(每次所得金币数是四舍五入(当月连续签到天数+4))
                    <br />2.分享作品，点击作品展示页下方的分享，按照上面的提示做
                    <br />3.点赞作品，点赞1作品金币+4
                    <br />4.被点赞作品，被点赞1次金币+4
                    <br />5.邀请他人注册(详情请看菜单栏的“邀请用户领金币”)
                    <br /></span></v-tooltip>
                <Item v-if="itemData" :host="host" :myInfo="myInfo" :page="0" :id="id" :data="itemData"></Item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-5 sd pm">
            <span class="text-h5 text--primary" cols="24">用户介绍</span>
            <v-btn v-on:click="edit == 2 ? (edit = 0) : (edit = 2)" class="ml-2" v-if="myInfo && myInfo.id == user.id"
              :class="'sd ' + (edit != 2 ? null : 'tg')" :color="edit == 2 ? null : 'primary'">
              <v-icon>mdi-square-edit-outline</v-icon>
              <span v-if="edit == 2">取消</span>编辑</v-btn>
            <v-btn v-on:click="edits(2)" v-if="myInfo && myInfo.id == user.id && edit == 2" class="sd">保存</v-btn>
            <br />
            <br />
            <MarkdownContext :text="user.introduce" v-if="!myInfo || user.id != myInfo.id || edit != 2" />
            <span v-else>
              <MarkdownInput clearable clear-icon="mdi-close-circle" filled label="介绍" v-model="introduce" />
            </span>
          </v-card>
        </v-col>
        <v-col cols="12">
          <span>他的作品：</span>
          <a class="float-right" :href="'#page=search&name=&type=0&p=1&s=0&author=' +
            user.id +
            '&title=' +
            user.nickname +
            '的作品'
            ">
            <v-btn class="sd" color="primary">更多</v-btn></a>
          <br />
        </v-col>
        <v-col cols="12" v-if="worklist && worklist[0]">
          <v-row>
            <v-col v-for="j in worklist" cols="6" sm="4" md="3" lg="2" :key="j">
              <s-workcard :work="j" :user="userlist[j.author][0]" :host="host"></s-workcard>
            </v-col>
          </v-row>
        </v-col>
        <span v-else>当前用户还没有作品哦</span>
        <v-col cols="12"> <span>他的收藏夹：</span></v-col>
        <v-col cols="12" v-if="folderlist && folderlist[0]">
          <v-row cols="12">
            <v-col v-for="j in folderlist" cols="6" sm="4" md="3" lg="2" :key="j">
              <s-foldercard :folder="j" :host="host" :user="user.nickname"></s-foldercard>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-else><span>当前用户还没有收藏夹哦</span></v-col>

        <CommentArea :id="id" :host="host" :type="0" :myInfo="myInfo"></CommentArea>
      </div>
    </v-row>
  </v-container>
</template>
<script setup>
import MarkdownContext from "./MarkdownContext.vue";
import MarkdownInput from "./MarkdownInput.vue";
import { defineProps, ref, defineEmits } from "vue";
import CommentArea from "./CommentArea.vue";
import SWorkcard from "./WorkCard.vue";
import SFoldercard from "./SFolder.vue";
import Item from "./SItem.vue";
import SAvatar from "./SAvatar.vue";
defineProps(["host", "myInfo"]);
const emit = defineEmits(["updateValue"]);
const headwears=ref([]),itemData=ref()
var user = ref({}),
  id = window.getQueryString("id"),
  date = window.convertTimestamp;
var followu=ref(0)
var folderlist = ref([]),
  worklist = ref([]),
  userlist = ref([]),
  edit = ref(0),
  nickname = ref(""),
  introduce = ref("");
const edits = (n) => {
  let s = ["", "nickname", "introduce"][n];
  let data = {};
  data[s] = eval(s + ".value");
  window
    .post("user/info/update", {
      data,
      p: "changeinfo",
    })
    .then(() => {
      emit("reload");
    });
};
const follow = () => {
  window.post("user/follow" + (followu.value ? "/cancel" : ""), { id: window.getQueryString('id') })
    .then(() => {
      followu.value = !followu.value
      window.reload2()
    })
};
window.get("user/info", { id }).then((data) => {
  user.value = data.data[0];
  nickname.value = user.value.nickname;
  introduce.value = user.value.introduce;
  followu.value=user.value.followu
  window.setTitle(user.value.nickname)
});

window.get("collections/folders", { id }).then((data) => {
  folderlist.value = data.folders;
});
window.get("work/user", { id, l: 6 }).then((data) => {
  worklist.value = data.data;
  userlist.value = data.userlist;
});
window.get("item/work", { id: id, useto: 0 })
    .then(data => {
      data=data.data
      itemData.value=data
      headwears.value=data
      .filter(d=>d.item.category=='Pendant' && (!d.expirationTime || d.expirationTime < new Date / 1000))
      .map(d=>d.item.thumbId2)
    })

const signin = () => {
  window.post("user/signin");
};
window.setTitle('用户')
</script>