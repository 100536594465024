<template>
  <v-container v-if="myInfo">
    <v-row>
      <v-col class="mx-auto">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="sd mx-auto pa-8 my-12"
              >我的物品
              <br />
              <span
                color="accent"
                class="text-subtitle-1 mt-2"
                v-bind="attrs"
                v-on="on"
              >
                <span class="text--disabled">金币：</span>
                <span style="color: #ff9800">{{ myInfo.coins }}</span>
                <v-btn elevation="2" v-on:click="getCoins()"
                  >金币记录</v-btn
                ></span
              >
              <v-row class="mt-3" v-if="bagItems">
                <v-col
                  cols="3"
                  sm="2"
                  md="3"
                  lg="2"
                  v-for="i in bagItems"
                  :key="i"
                >
                  <s-bag-item
                    :info="i"
                    :host="host"
                    :c="() => (selection = i)"
                  ></s-bag-item>
                </v-col>
              </v-row>
              <div v-else>
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                正在载入数据……
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="sd mx-auto pa-8 my-12"
              >商店
              <v-row v-if="storeItems">
                <v-col
                  cols="6"
                  sm="4"
                  md="6"
                  lg="4"
                  v-for="i in storeItems.filter((item) => item.isBuyable)"
                  :key="i"
                >
                  <s-store-item
                    :info="i"
                    :host="host"
                    :c="() => (selection = { item: i, buy: true })"
                  ></s-store-item
                ></v-col>
              </v-row>
              <div v-else>
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                正在载入数据……
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <item-dialog
      :dialog="selection"
      v-if="selection"
      @close1="
        (r) => {
          selection = null;
          r && _get();
        }
      "
      :host="host"
      page="myitem"
      :myInfo="myInfo"
    ></item-dialog>
  </v-container>
</template>
<script setup>
import ItemDialog from "./ItemDialog.vue";
import SBagItem from "./BagItem.vue";
import SStoreItem from "./SStore.vue";
import { ref, defineProps } from "vue";
defineProps(["host", "myInfo"]);
var storeItems = ref(null),
  bagItems = ref(null),
  selection = ref(false);
const _get = () => {
  bagItems.value = storeItems.value = null;
  window.get("item/store").then((data) => {
    storeItems.value = data.data;
  });
  window.get("item/bag").then((data) => {
    bagItems.value = data.data;
  });
};
_get();
function getCoins() {
  window.get("user/records").then((d) => {
    let s = "";
    for (let i = 0; i < d.length; i++) {
      s += `<br>
							<span class="body-1" style="color:#333">${d[i].note}</span><br>
							<span class="body-2" style="margin-top:-5px">
							<span style="color:#555">${d[i].coin_count}</span>
							<span style="color:#aaa">${d[i].create_time}</span>
							</span>
							`;
    }
    window.dialog(`
						<div style="height: 300px; overflow: auto; width:100%">
							${s}
						</div>
						`);
  });
}
window.setTitle('我的物品')
</script>