<template>
    <v-container>
        <div>
            <center>编程猫作品分析</center>
            <br>
            <v-file-input label="编程猫作品文件" accept=".bcm4" v-model="files"></v-file-input>
            <v-btn color="secondary" elevation="2" class="mx-5" @click="convertFiles()">开始分析</v-btn>
            <div id="chart"></div>
            <div v-if="c_data">
                <span>总{{chose?'':'有效'}}积木数：{{ totalBlock }}</span>
                <span class="pl-4">总{{chose?'':'有效'}}片段数：{{ chose ? c_data.part : c_data.validPart }}</span>
                <v-radio-group inline v-model="chose">
                    <v-radio label="全部" :value="true"></v-radio>
                    <v-radio label="仅有效" :value="false"></v-radio>
                </v-radio-group>
            </div>
        </div>
    </v-container>
</template>
<script setup>
import { ref, watch } from 'vue'
var files = ref([]), c_data = ref()
const totalBlock = ref(0), chose = ref(true)
function convertFiles() {

    var formData = new FormData();
    formData.append("file", files.value[0]);

    fetch(
        "https://service-dq726wx5-1302921490.sh.apigw.tencentcs.com/tool/bcm",
        {
            method: "POST",
            body: formData,
        }
    ).then((data) => data.json()).then((data)=>{
        let option = {
            title: {
                text: '作品数据统计--40code'
            },
            series: [
                {
                    type: 'pie',
                    data: [
                    ]
                }
            ],
        }
        var myChart = window.echarts.init(document.getElementById('chart'), null, {
            width: 500,
            height: 400
        });
        myChart.setOption(option);
        console.log(data);
        
        c_data.value = data
        const { prefixCountAll, prefixCountAllValid } = data
        const update = () => {
            let pc = chose.value ? prefixCountAll : prefixCountAllValid
            console.log(pc)
            let l = [], s = 0;
            for (let key in pc) {
                let p = pc[key]
                s += p
            }
            totalBlock.value = s
            for (let key in pc) {
                let p = pc[key]
                l.push({ name: `${key}(${p})`, value: p })
            }
            console.log(l)
            option.series[0].data = l
            console.log(l, option)
            myChart.setOption(option);
        }
        update()
        watch(chose, () => update())

    })
}
window.setTitle('编程猫作品分析')
</script>