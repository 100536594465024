<style>
:not(.not-input-clearable) textarea{
    margin-right: -44px !important;
}
</style>
<template>
    <div>
        <v-row>
            <v-col :cols="show && mode ? 6 : 12">
                <v-textarea v-bind="$attrs" v-model="text" hint="支持markdown和部分html，右下角可以拉大"
                    :id="random_id"></v-textarea>
            </v-col>
            <v-col v-if="show" :cols="mode ? 6 : 12" class="overflow-auto  mt-3" :style="mode && `max-height:`+iheight">
                <MarkdownContext :text="text" />
            </v-col>
        </v-row>

        <span v-if="!disabledMarkdown" :class="`d-flex mb-n4 mt-n${mode ? 9 : 5}`">
            <v-switch label="开启预览" color="primary" class="mx-3" v-model="show"></v-switch>
            <v-switch v-if="show" :label="`布局：${['上下', '左右'][+mode]}`" color="primary" class="mx-3"
                v-model="mode"></v-switch>
        </span>
    </div>
</template>
<script setup>
import { ref, defineProps, defineModel, onUnmounted, onMounted } from 'vue';
import MarkdownContext from './MarkdownContext.vue'
defineProps(['disabledMarkdown'])
const text = defineModel({ default: 0 }), random_id = 'a' + Math.round(Math.random() * 1e12);
var show = ref(false), mode = ref(true)
var iheight = ref(0)

async function paste(e) {
    let i = (e = e.originalEvent).clipboardData,
        a = window.navigator.userAgent,
        n = e.target;
    if (e.clipboardData && e.clipboardData.items && !(i.items && 2 === i.items.length && "string" === i.items[0].kind && "file" === i.items[1].kind && i.types && 2 === i.types.length && "text/plain" === i.types[0] && "Files" === i.types[1] && a.match(/Macintosh/i) && Number(a.match(/Chrome\/(\d{2})/i)[1]) < 49))
        for (var s = 0; s < i.items.length; s++) {
            var r = i.items[s];
            if ("file" == r.kind) {
                var l = r.getAsFile();
                let img = await window.uploadImage(l, 0.5)
                let _text = "![](https://abc.520gxx.com/static/internalapi/asset/" + img + ")";
                text.value = text.value.slice(0, n.selectionStart) + _text + text.value.substring(n.selectionEnd, text.value.length)
            }
        }
}
let intervalId
onMounted(() => {
    var $ = window.$
    $('#' + random_id).bind('paste', paste)
    intervalId = setInterval(() => {
        if (window.innerWidth >= 960)
            iheight.value = $('#' + random_id).height()+30 + 'px'
        else
            iheight.value = 'auto'
    }, 100);
})

onUnmounted(() => {
    clearInterval(intervalId)
    window.$('#' + random_id).unbind('paste')
})
</script>