<template>
    <v-dialog v-model="dialog2" max-width="600">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="$emit('close1')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>使用</v-toolbar-title>
            </v-toolbar>
            <v-list-item>
                <v-row class="pa-3" v-if="items && items.length">
                    <v-col cols="3" sm="2" md="3" lg="2" v-for="item in items.filter(i=>page == '1' && i.item.canUseToProject && (!i.item.canUseToSelfOnly||power) || page == '0' && i.item.canUseToUser && (!i.item.canUseToSelfOnly||power) || page == 'studio' && i.item.canUseToStudio || page==3 && i.item.canUseToForumPost)" :key="item">
                        <s-bag-item :info="item" :host="host" :item="item" :c="(id)=>(console.log(id),$emit('close1',id))"></s-bag-item>
                    </v-col>
                </v-row>
                <v-row class="pa-3" v-else>
                    你还没有可以使用的物品呢……
                </v-row>
            </v-list-item>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { defineProps, ref, defineEmits,watch } from 'vue';
import SBagItem from './BagItem.vue'
var dialog2 = ref(true)
watch(dialog2,(value)=>{
    if(!value) emit('close1')
})
// console.log(114)
defineProps(['host', 'show', 'items','page','power'])
const emit = defineEmits(['updateValue'])
</script>